import { Prettify } from 'types/common';

export interface Toast {
  title: string;
  message?: string;
  type?: 'danger' | 'success' | 'info';
  icon?: string;
  class?: string;
  closable?: boolean;
  action?: string;
  actionCallback?: (e: Event) => void;
  time?: number;
  id?: number;
}

export type ToastInput = Prettify<
  Omit<Toast, 'message'> & {
    message?: string | string[];
    error?: unknown;
  }
>;

const toasts = ref<Array<Toast>>([]);

let toastId = 1;

export default function () {
  const { t } = useNuxtApp().$i18n;

  const DEFAULT_TIME = 5;

  const DEFAULT_TOAST: Toast = {
    title: t('common.errors.unknown'),
    message: undefined,
    type: 'danger',
    icon: undefined,
    class: undefined,
    closable: true,
    action: undefined,
    actionCallback: undefined,
    time: DEFAULT_TIME
  };

  const addToast = (toast: ToastInput = {} as ToastInput): number => {
    if (toast.error && !toast.message) {
      if (toast.error instanceof Error) {
        toast.message = toast.error.message;
      } else if (typeof toast.error === 'string') {
        toast.message = toast.error;
      }
      delete toast.error;
    }

    if (Array.isArray(toast.message)) {
      toast.message = toast.message.join('\n');
    }

    const newToast: Toast = Object.assign(
      { id: toastId },
      DEFAULT_TOAST,
      toast
    );
    toastId++;
    return toasts.value.push(newToast) - 1;
  };

  return {
    addToast,
    toasts
  };
}
