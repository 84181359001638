import { isObject } from '../types/common';

interface HttpMethodOptions {
  getJson?: boolean;
  throwError?: boolean;
}

const defaultOptions: HttpMethodOptions = { getJson: true, throwError: false };

function checkError(content: unknown) {
  if (
    isObject(content) &&
    (('detail' in content && typeof content.detail === 'string') ||
      ('statusCode' in content &&
        (content.statusCode.toString().startsWith('4') ||
          content.statusCode.toString().startsWith('5')) &&
        'message' in content &&
        typeof content.message === 'string'))
  ) {
    throw new Error(content.detail || content.message);
  }
}

export default function () {
  async function getDel<T>(
    url: string,
    options: HttpMethodOptions = {},
    method: 'GET' | 'DELETE' = 'GET'
  ) {
    const { getJson, throwError } = Object.assign({}, defaultOptions, options);

    const response = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Origin': 'http://localhost:3000'
      }
    });
    if (getJson) {
      const content = await response.json();
      if (typeof content === 'string') {
        try {
          return JSON.parse(content) as T;
        } catch (e) {
          return content as unknown as T;
        }
      }
      if (throwError) {
        checkError(content);
      }
      return content as T;
    } else {
      if (throwError) {
        checkError(response);
      }
      return response as T;
    }
  }

  async function postPut<T>(
    url: string,
    data: Record<string, unknown>,
    options: HttpMethodOptions = {},
    method: 'POST' | 'PUT' = 'POST'
  ) {
    const { getJson, throwError } = Object.assign({}, defaultOptions, options);

    const response = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (getJson) {
      const content = await response.json();
      if (typeof content === 'string') {
        try {
          return JSON.parse(content) as T;
        } catch (e) {
          return content as unknown as T;
        }
      }
      if (throwError) {
        checkError(content);
      }
      return content as T;
    } else {
      if (throwError) {
        checkError(response);
      }
      return response as T;
    }
  }

  async function get<T>(url: string, options: HttpMethodOptions = {}) {
    return await getDel<T>(url, options, 'GET');
  }

  async function del(url: string, options: HttpMethodOptions = {}) {
    return await getDel(url, options, 'DELETE');
  }

  async function post<T>(
    url: string,
    data: Record<string, unknown>,
    options: HttpMethodOptions = {}
  ) {
    return await postPut<T>(url, data, options, 'POST');
  }

  async function put<T>(
    url: string,
    data: Record<string, unknown>,
    options: HttpMethodOptions = {}
  ) {
    return await postPut<T>(url, data, options, 'PUT');
  }

  return { get, post, del, put };
}
