import {
  mdiBusSchool,
  mdiHospital,
  // mdiPalmTree,
  mdiSchool,
  mdiShopping,
  mdiSilverwareForkKnife,
  mdiTownHall
} from '@mdi/js';

import messages from '../locales';
import type { Layer } from '../types/properties';

export const valuesMap = Object.fromEntries(
  Object.values(messages).flatMap(locale => {
    if (
      !('paramsAliases' in locale) ||
      !locale.paramsAliases ||
      !Object.keys(locale.paramsAliases).length
    ) {
      return [];
    }
    return Object.entries(locale.paramsAliases).map(([key, value]) => {
      return [value, key.replace('_alt', '')];
    });
  })
);

export const rentPrices = [
  { text: '$800', value: 800 },
  { text: '$900', value: 900 },
  { text: '$1,000', value: 1000 },
  { text: '$1,100', value: 1100 },
  { text: '$1,200', value: 1200 },
  { text: '$1,300', value: 1300 },
  { text: '$1,400', value: 1400 },
  { text: '$1,500', value: 1500 },
  { text: '$1,600', value: 1600 },
  { text: '$1,700', value: 1700 },
  { text: '$1,800', value: 1800 },
  { text: '$1,900', value: 1900 },
  { text: '$2,000', value: 2000 },
  { text: '$2,200', value: 2200 },
  { text: '$2,400', value: 2400 },
  { text: '$2,500', value: 2500 },
  { text: '$2,600', value: 2600 },
  { text: '$2,800', value: 2800 },
  { text: '$3,000', value: 3000 },
  { text: '$3,200', value: 3200 },
  { text: '$3,400', value: 3400 },
  { text: '$3,500', value: 3500 },
  { text: '$3,600', value: 3600 },
  { text: '$3,800', value: 3800 },
  { text: '$4,000', value: 4000 },
  { text: '$4,200', value: 4200 },
  { text: '$4,400', value: 4400 },
  { text: '$4,500', value: 4500 },
  { text: '$4,600', value: 4600 },
  { text: '$4,800', value: 4800 },
  { text: '$5,000', value: 5000 },
  { text: '$7,500', value: 7500 },
  { text: '$10,000', value: 10000 },
  { text: '$15,000', value: 15000 }
];

export const buyPrices = [
  // { text: '$150k', value: 150000 },
  // { text: '$200k', value: 200000 },
  // { text: '$250k', value: 250000 },
  { text: '$300k', value: 300000 },
  { text: '$350k', value: 350000 },
  { text: '$400k', value: 400000 },
  { text: '$450k', value: 450000 },
  { text: '$500k', value: 500000 },
  { text: '$550k', value: 550000 },
  { text: '$600k', value: 600000 },
  { text: '$650k', value: 650000 },
  { text: '$700k', value: 700000 },
  { text: '$750k', value: 750000 },
  { text: '$800k', value: 800000 },
  { text: '$850k', value: 850000 },
  { text: '$900k', value: 900000 },
  { text: '$950k', value: 950000 },
  { text: '$1m', value: 1000000 },
  { text: '$1.1m', value: 1100000 },
  { text: '$1.2m', value: 1200000 },
  { text: '$1.25m', value: 1250000 },
  { text: '$1.4m', value: 1400000 },
  { text: '$1.5m', value: 1500000 },
  { text: '$1.6m', value: 1600000 },
  { text: '$1.7m', value: 1700000 },
  { text: '$1.75m', value: 1750000 },
  { text: '$1.8m', value: 1800000 },
  { text: '$1.9m', value: 1900000 },
  { text: '$2m', value: 2000000 },
  { text: '$2.25m', value: 2250000 },
  { text: '$2.5m', value: 2500000 },
  { text: '$2.75m', value: 2750000 },
  { text: '$3m', value: 3000000 },
  { text: '$3.5m', value: 3500000 },
  { text: '$4m', value: 4000000 },
  { text: '$5m', value: 5000000 },
  { text: '$10m', value: 10000000 },
  { text: '$20m', value: 20000000 },
  { text: '$30m', value: 30000000 },
  { text: '$40m', value: 40000000 },
  { text: '$50m', value: 50000000 }
];

interface SortByOption {
  value: string;
  text: string;
}

export const sortByOptions: SortByOption[] = [
  {
    value: 'yearBuilt:desc',
    text: 'search.sortByOptions.mostRecent'
  },
  {
    value: 'yearBuilt:asc',
    text: 'search.sortByOptions.oldest'
  },
  {
    value: 'listPrice:desc',
    text: 'search.sortByOptions.highestPrice'
  },
  {
    value: 'listPrice:asc',
    text: 'search.sortByOptions.lowestPrice'
  },
  {
    value: 'bathsFull:desc',
    text: 'search.sortByOptions.mostBathrooms'
  },
  {
    value: 'bathsFull:asc',
    text: 'search.sortByOptions.fewestBathrooms'
  },
  {
    value: 'bedrooms:desc',
    text: 'search.sortByOptions.mostBedrooms'
  },
  {
    value: 'bedrooms:asc',
    text: 'search.sortByOptions.fewestBedrooms'
  },
  {
    value: 'propertyArea:desc',
    text: 'search.sortByOptions.largestArea'
  },
  {
    value: 'propertyArea:asc',
    text: 'search.sortByOptions.smallestArea'
  }
];

export const sortProjectsByOptions: SortByOption[] = [
  {
    value: 'yearCompletion:asc',
    text: 'search.sortByOptions.firstToFinish'
  },
  {
    value: 'yearCompletion:desc',
    text: 'search.sortByOptions.lastToFinish'
  },
  {
    value: 'city',
    text: 'search.sortByCity'
  }
];

export const areaOptions = [
  500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 3000, 3500, 4000, 4500,
  5000, 7500, 10000
];

export const condoCostOptions = [
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
  1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000
];

export const yearOptions = Array.from(
  { length: 2026 - 1980 + 1 },
  (_, index) => 1980 + index
) as number[];

export const hopaOptions = [
  {
    text: 'search.hopaOptions.any',
    value: null
  },
  {
    text: 'search.hopaOptions.hopa-eligible',
    value: 'hopa-eligible'
  },
  {
    text: 'search.hopaOptions.no-hopa',
    value: 'no-hopa'
  }
];

export const buyTypeOptions = [
  {
    text: 'search.buyTypeOptions.used',
    value: 'RES'
  },
  {
    text: 'search.buyTypeOptions.new',
    value: 'NEW'
  },
  {
    text: 'search.buyTypeOptions.rent',
    value: 'RNT'
  },
  {
    text: 'search.buyTypeOptions.sold',
    value: 'SOL'
  },
  {
    text: 'search.buyTypeOptions.rented',
    value: 'RTD'
  }
];

export const buyTypeOptionsAlt = [
  {
    text: 'search.buyTypeOptions.buy',
    value: 'RES'
  },
  {
    text: 'search.buyTypeOptions.new',
    value: 'NEW'
  },
  {
    text: 'search.buyTypeOptions.rent',
    value: 'RNT'
  },
  {
    text: 'search.buyTypeOptions.sold',
    value: 'SOL'
  },
  {
    text: 'search.buyTypeOptions.rented',
    value: 'RTD'
  }
];

export const propertySubTypesOptions = [
  {
    text: 'search.singleFamilyResidences',
    value: 'singleFamilyResidence',
    typeText: 'search.housesType',
    typeValue: 'house'
  },
  {
    text: 'search.townhouses',
    value: 'townhouse',
    typeText: 'search.housesType',
    typeValue: 'house'
  },
  {
    text: 'search.villas',
    value: 'villa',
    typeText: 'search.housesType',
    typeValue: 'house'
  },
  {
    text: 'search.condominiums',
    value: 'condo',
    typeText: 'search.condosType',
    typeValue: 'apartment'
  },
  {
    text: 'search.multiFamilies',
    value: 'multiFamily',
    typeText: 'search.condosType',
    typeValue: 'apartment'
  },
  {
    text: 'search.buildings',
    value: 'building',
    typeText: 'search.condosType',
    typeValue: 'apartment'
  }
];

export const propertyTypesOptions = [
  {
    text: 'search.housesType',
    value: 'house'
  },
  {
    text: 'search.condosType',
    value: 'apartment'
  }
];

export const propertySubTypeOptions = [
  {
    text: 'search.singleFamilyResidence',
    value: 'singleFamilyResidence',
    typeText: 'search.houseType',
    typeValue: 'house',
    context: 'female'
  },
  {
    text: 'search.townhouse',
    value: 'townhouse',
    typeText: 'search.houseType',
    typeValue: 'house',
    context: 'male'
  },
  {
    text: 'search.villa',
    value: 'villa',
    typeText: 'search.houseType',
    typeValue: 'house',
    context: 'female'
  },
  {
    text: 'search.condominium',
    value: 'condo',
    typeText: 'search.condoType',
    typeValue: 'apartment',
    context: 'male'
  },
  {
    text: 'search.multiFamily',
    value: 'multiFamily',
    typeText: 'search.condoType',
    typeValue: 'apartment',
    context: 'male'
  },
  {
    text: 'search.apartment',
    value: 'apartment',
    typeText: 'search.condoType',
    typeValue: 'apartment',
    context: 'male'
  },
  {
    text: 'search.building',
    value: 'building',
    typeText: 'search.condoType',
    typeValue: 'apartment',
    context: 'male'
  }
] as {
  text: string;
  value: string;
  typeText: string;
  typeValue: string;
  context: 'male' | 'female';
}[];

export const propertyTypeOptions = [
  {
    text: 'search.houseType',
    value: 'house'
  },
  {
    text: 'search.condoType',
    value: 'apartment'
  }
];

export const projectTypeOptions = [
  {
    text: 'project.preConstruction',
    value: 'pre-construction'
  },
  {
    text: 'project.underConstruction',
    value: 'under-construction'
  },
  {
    text: 'project.built',
    value: 'built'
  },
  {
    text: 'project.onTheMarket',
    value: 'on-the-market'
  }
];

export const placesOptions: {
  key: Layer;
  title: string;
  icon: string;
}[] = [
  {
    key: 'malls',
    title: 'search.mapLayers.malls',
    icon: mdiShopping
  },
  {
    key: 'restaurants',
    title: 'search.mapLayers.restaurants',
    icon: mdiSilverwareForkKnife
  },
  {
    key: 'college-universities',
    title: 'search.mapLayers.collegeUniversities',
    icon: mdiSchool
  },
  {
    key: 'public-schools',
    title: 'search.mapLayers.publicSchools',
    icon: mdiBusSchool
  },
  {
    key: 'private-schools',
    title: 'search.mapLayers.privateSchools',
    icon: mdiTownHall
  },
  {
    key: 'hospitals',
    title: 'search.mapLayers.hospitals',
    icon: mdiHospital
  }
];

type Connector = keyof (typeof messages)['us']['connectors'];

export const viewOptions: {
  en: string;
  es: string;
  'value-en'?: string;
  'value-es'?: string;
  connector: Connector;
  value?: string;
}[] = [
  {
    en: 'Any view',
    es: 'Cualquier vista',
    'value-en': 'any',
    'value-es': 'cualquiera',
    connector: 'with'
  },
  { en: 'Ocean View', es: 'Vista al mar', connector: 'with' },
  { en: 'Direct Ocean', es: 'Océano directo', connector: 'withViewToThe' },
  { en: 'Bay', es: 'Bahía', connector: 'withViewToThe_female' },
  { en: 'Intracoastal View', es: 'Vista de la costa', connector: 'with' },
  { en: 'Lagoon', es: 'Laguna', connector: 'withViewToThe_female' },
  {
    en: 'Skyline',
    es: 'Luces de la ciudad',
    connector: 'withViewToThe_female_plural'
  },
  { en: 'Lake', es: 'Lago', connector: 'withViewToThe' },
  { en: 'River', es: 'Río', connector: 'withViewToThe' },
  { en: 'Garden View', es: 'Vista al jardín', connector: 'with' },
  { en: 'Garden', es: 'Jardín', connector: 'withViewToThe' },
  { en: 'Golf View', es: 'Vista al Golf', connector: 'with' },
  { en: 'Golf Course', es: 'Campo de golf', connector: 'withViewToThe' },
  { en: 'Water View', es: 'Vista al agua', connector: 'with' },
  { en: 'Pool', es: 'Piscina', connector: 'withViewToThe_female' },
  {
    en: 'Pool Area View',
    es: 'Vista de la zona de la piscina',
    connector: 'with'
  },
  {
    en: 'Tennis Court View',
    es: 'Vista a la pista de tenis',
    connector: 'with'
  },
  { en: 'Club Area View', es: 'Vista de la zona del club', connector: 'with' },
  { en: 'Water', es: 'Agua', connector: 'withViewToThe' },
  { en: 'Canal', es: 'Canal', connector: 'withViewToThe' },
  { en: 'Other View', es: 'Otra vista', connector: 'with' }
];

export const propertyTypeMap = Object.fromEntries(
  propertySubTypesOptions.map(({ typeValue, value }) => [value, typeValue])
);

export function valueToParam(
  value: string | number | boolean,
  alias: boolean | 'force' = false,
  t = (_str: string, fallback = '') => fallback
) {
  if (alias && typeof value === 'string') {
    const result = t(`paramsAliases.${value}`);
    if (result && result !== `paramsAliases.${value}`) {
      return result;
    }
    if (alias === 'force') {
      return value;
    }
  }
  return (
    value
      .toString()
      .toLowerCase()
      // .replaceAll(' - ', '---')
      // .replaceAll('-', '--')
      .replaceAll(' ', '-')
      .replaceAll('_', '-')
      .replaceAll('/', '-slash-')
      .normalize('NFD')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
  );
}

export function paramToValue(param: string | number) {
  return (
    param
      .toString()
      .replaceAll('-slash-', '/')
      // .replaceAll('---', ' [%DASH%] ')
      // .replaceAll('--', '[%DASH%]')
      .replaceAll('-', ' ')
      // .replaceAll('[%DASH%]', '-')
      .replace('miami dade', 'miami-dade')
      .replace('st lucie', 'st. lucie')
  );
}

export function capitalize(word: string) {
  if (word === 'on' || word === 'at' || word === 'the' || word === 'by') {
    return word;
  }
  if (word === 'sls') {
    return 'SLS';
  }
  return word.slice(0, 1).toUpperCase() + word.slice(1);
}

export function capitalizeWords(string: string) {
  return (string || '')
    .split(' ')
    .map(capitalize)
    .join(' ')
    .split('-')
    .map(capitalize)
    .join('-');
}

export function formatShortPrice(v: number, decimals = 0, locale = 'en-US') {
  if (v >= 1000000) {
    return `${+(v / 1000000).toFixed(decimals)}M`;
  }

  if (v >= 1000) {
    return `${+(v / 1000).toFixed(decimals)}K`;
  }

  const language = locale.startsWith('es') ? 'es' : 'en';

  return v || v === 0 ? Intl.NumberFormat(language).format(v) : '';
}

export function getPriceButtonText(
  filters: { priceFrom: number; priceTo: number },
  locale: string
): string {
  if (!filters.priceFrom && !filters.priceTo) {
    return '';
  }

  if (filters.priceFrom && filters.priceTo) {
    return `$${formatShortPrice(
      filters.priceFrom,
      2,
      locale
    )} - $${formatShortPrice(filters.priceTo, 2, locale)}`;
  }

  if (filters.priceFrom) {
    return `$${formatShortPrice(filters.priceFrom, 2, locale)}+`;
  }

  return `$0-$${formatShortPrice(filters.priceTo, 2, locale)}`;
}

export function getRangeButtonText(
  filters: { from: number; to: number },
  locale: string
): string {
  if (!filters.from && !filters.to) {
    return '';
  }

  if (filters.from && filters.to) {
    return `${formatShortPrice(filters.from, 2, locale)} - ${formatShortPrice(
      filters.to,
      2,
      locale
    )}`;
  }

  if (filters.from) {
    return `${formatShortPrice(filters.from, 2, locale)}+`;
  }

  return `0-${formatShortPrice(filters.to, 2, locale)}`;
}

export function getBedroomsAndBathroomsButtonText(
  filters: {
    bedroomsFrom: number;
    bedroomsTo: number;
    bathroomsFrom: number;
    bathroomsTo: number;
  },
  t: (str: string) => string
) {
  if (
    !filters.bedroomsFrom &&
    !filters.bedroomsTo &&
    !filters.bathroomsFrom &&
    !filters.bathroomsTo
  ) {
    return '';
  }

  const bedrooms = filters.bedroomsFrom
    ? filters.bedroomsTo
      ? `${filters.bedroomsFrom}-${filters.bedroomsTo}`
      : `${filters.bedroomsFrom}+`
    : filters.bedroomsTo
    ? `0-${filters.bedroomsTo}`
    : '';

  const bathrooms = filters.bathroomsFrom
    ? filters.bathroomsTo
      ? `${filters.bathroomsFrom}-${filters.bathroomsTo}`
      : `${filters.bathroomsFrom}+`
    : filters.bathroomsTo
    ? `0-${filters.bathroomsTo}`
    : '';

  if (bedrooms && bathrooms) {
    return `${bedrooms} ${t('search.bedroomsShort')},  ${bathrooms} ${t(
      'search.bathsFullShort'
    )}`;
  }

  if (bedrooms) {
    return `${bedrooms} ${t('search.bedroomsShort')}`;
  }

  return `${bathrooms} ${t('search.bathrooms').toLowerCase()}`;
}

export function getQueryBy(collection?: string) {
  if (collection === 'properties') {
    return [
      'amenities',
      'mlsArea',
      'postalCode',
      'city',
      'county',
      'full'
    ].join(',');
  } else if (collection === 'projects') {
    return ['projectName', 'address', 'city', 'county'].join(',');
  } else {
    return [
      'amenities',
      'mlsArea',
      'postalCode',
      'city',
      'county',
      'full'
    ].join(',');
  }
}

export function createSearchParams(
  paramsObjectArg: Record<string, string | number | boolean | string[]>,
  language: 'en' | 'es' = 'es'
): Record<string, string> {
  const paramsObject = { ...paramsObjectArg };

  const isSingle = paramsObject.isSingle;

  const collection = (paramsObject.collection || 'properties').toString();

  const propertySubType = paramsObject.propertySubType as string[] | null;

  const isProject =
    collection === 'projects' || propertySubType?.[0] === 'building';

  if (propertySubType?.[0] === 'building') {
    paramsObject.projectStatus = 'on-the-market';
  }

  const filterBy: string[] = Array.isArray(paramsObject.filterBy)
    ? paramsObject.filterBy
    : [];

  if (!paramsObject.sortBy?.toString().includes(':')) {
    delete paramsObject.sortBy;
  }

  if (paramsObject.county === 'constempty') {
    delete paramsObject.county;
  }

  if (paramsObject.city === 'constempty') {
    delete paramsObject.city;
  }

  if (paramsObject.zipcode === 'constempty') {
    delete paramsObject.zipcode;
  }

  if (paramsObject.neighborhood === 'constempty') {
    delete paramsObject.neighborhood;
  }

  if (paramsObject.location === 'constempty') {
    delete paramsObject.location;
  }

  if (paramsObject.geolocation) {
    // filterBy.push(`geopoint:(${paramsObject.geolocation},5.1km)`);
    delete paramsObject.location;
    if (!paramsObject.sortBy) {
      paramsObject.sortBy = `geopoint(${paramsObject.geolocation}):asc`;
    }
  } else if (
    paramsObject.neighborhood &&
    isProject &&
    (paramsObject.q === '*' || !paramsObject.q)
  ) {
    paramsObject.q = paramsObject.neighborhood;
  } else if (paramsObject.neighborhood && !isProject) {
    filterBy.push(`neighborhood:=${paramsObject.neighborhood}`);
  } else if (Array.isArray(paramsObject.zipcode)) {
    filterBy.push(`postalCode:[${paramsObject.zipcode.join(',')}]`);
  } else if (paramsObject.zipcode) {
    filterBy.push(`postalCode:=${paramsObject.zipcode}`);
  } else if (paramsObject.city) {
    filterBy.push(`city:=${paramsObject.city}`);
  } else if (paramsObject.county) {
    filterBy.push(`county:${paramsObject.county}`);
  }

  const location = paramsObject.location?.toString().trim() || '';

  // Uses paramsObject.q to force search when location is deleted
  const queryParams: Record<string, string> = {
    q: location || (paramsObject.q as string) || '*',
    query_by: (paramsObject.queryBy || getQueryBy(collection)).toString(),
    locale: language
    // collectionName:
    //   collection === 'projects' ? 'new_projects' : 'properties_test'
  };

  if (paramsObject.page) {
    queryParams.page = paramsObject.page.toString();
  }

  if (paramsObject.perPage) {
    queryParams.per_page = paramsObject.perPage.toString();
  }

  if (paramsObject.sortBy) {
    queryParams.sort_by = paramsObject.sortBy.toString();
  } else if (paramsObject.bathroomsFrom || paramsObject.bedroomsFrom) {
    queryParams.sort_by = [
      paramsObject.bathroomsFrom
        ? `${isProject ? 'maxBaths' : 'bathsFull'}:asc`
        : '',
      paramsObject.bedroomsFrom
        ? `${isProject ? 'maxBeds' : 'bedrooms'}:asc`
        : ''
    ]
      .filter(Boolean)
      .join(',');
  } else if (isProject) {
    queryParams.sort_by = 'yearCompletion:desc';
  } else {
    queryParams.sort_by = 'yearBuilt:desc';
  }

  if (paramsObject.offset) {
    queryParams.offset = paramsObject.offset.toString();
  }

  if (paramsObject.limit) {
    queryParams.limit = paramsObject.limit.toString();
  }

  const propertyType = paramsObject.propertyType as string[] | null;

  if (propertySubType?.join(',') === 'building') {
    // projects request
  } else if (propertySubType?.length) {
    filterBy.push(`subType:[${propertySubType.join(',')}]`);
  } else if (propertyType?.length) {
    const types = propertySubTypesOptions
      .filter(v => v.value !== 'building' && propertyType.includes(v.typeValue))
      .map(v => v.value);
    filterBy.push(`subType:[${types.join(',')}]`);
  }

  if (paramsObject.buyType && !isProject) {
    const buyTypes = Array.isArray(paramsObject.buyType)
      ? paramsObject.buyType
      : [paramsObject.buyType];

    const buyTypeFilterBy: string[] = [];

    buyTypes.forEach(buyType => {
      const currentFilterBy = [];

      if (buyType === 'SOL') {
        if (!paramsObject.status) {
          currentFilterBy.push(`status:[Pending,Closed]`);
        }
        currentFilterBy.push(`type:[RES]`);
      } else if (buyType === 'RTD') {
        if (!paramsObject.status) {
          currentFilterBy.push(`status:[Pending,Closed,Rented]`);
        }
        currentFilterBy.push(`type:[RNT]`);
      } else if (buyType === 'RES') {
        if (!paramsObject.status) {
          currentFilterBy.push(
            `status:!=Pending && status:!=Closed && status:!=Rented`
          );
        }
        currentFilterBy.push(`type:[RES]`);
      } else if (buyType === 'RNT') {
        if (!paramsObject.status) {
          currentFilterBy.push(
            `status:!=Pending && status:!=Closed && status:!=Rented`
          );
        }
        currentFilterBy.push(`type:[RNT]`);
      } else {
        currentFilterBy.push(`type:[${buyType}]`);
      }
      if (currentFilterBy.length > 0) {
        buyTypeFilterBy.push(`(${currentFilterBy.join(' && ')})`);
      }
    });

    if (buyTypeFilterBy.length > 0) {
      filterBy.push(`(${buyTypeFilterBy.join(' || ')})`);
    }
  }

  if (paramsObject.shortTermLease) {
    filterBy.push(`leaseTerm:[Short Term Lease]`);
  }

  if (paramsObject.excludePendingAndUnderContract) {
    filterBy.push(`status:!=Active With Contract && status:!=Pending`);
  }

  if (paramsObject.inListing) {
    filterBy.push(`listingPFS:=true`);
  }

  if (paramsObject.status) {
    filterBy.push(`status:=${paramsObject.status}`);
  }

  if (isProject && paramsObject.projectStatus) {
    filterBy.push(`projectStatus:=${paramsObject.projectStatus}`);
  } else if (isProject && !isSingle) {
    filterBy.push(`projectStatus:=pre-construction`);
  }

  if (paramsObject.buildingName) {
    filterBy.push(`buildingName:=${paramsObject.buildingName}`);
  }

  // if (paramsObject.projectType) {
  //   filterBy.push(`projectStatus:=${paramsObject.projectType}`);
  // }

  if (paramsObject.bedroomsFrom) {
    filterBy.push(
      `${isProject ? 'maxBeds' : 'bedrooms'}:>=${paramsObject.bedroomsFrom}`
    );
  }

  if (paramsObject.bedroomsTo) {
    filterBy.push(
      `${isProject ? 'minBeds' : 'bedrooms'}:<=${paramsObject.bedroomsTo}`
    );
  }

  if (paramsObject.bathroomsFrom) {
    filterBy.push(
      `${isProject ? 'maxBaths' : 'bathsFull'}:>=${paramsObject.bathroomsFrom}`
    );
  }

  if (paramsObject.bathroomsTo) {
    filterBy.push(
      `${isProject ? 'minBaths' : 'bathsFull'}:<=${paramsObject.bathroomsTo}`
    );
  }

  const buyTypesString = Array.isArray(paramsObject.buyType)
    ? paramsObject.buyType.join(',')
    : (paramsObject.buyType || '').toString();

  const hasRent =
    buyTypesString?.includes('RNT') || buyTypesString?.includes('RTD');

  if (paramsObject.priceFrom) {
    filterBy.push(
      `${isProject ? 'maxPrice' : 'listPrice'}:>=${paramsObject.priceFrom}`
    );
  } else if (!paramsObject.omitPriceFilter && !isProject && !hasRent) {
    filterBy.push(`listPrice:>=${buyPrices[0]?.value}`);
  }

  if (paramsObject.priceTo) {
    filterBy.push(
      `${isProject ? 'minPrice' : 'listPrice'}:<=${paramsObject.priceTo}`
    );
  }

  if (paramsObject.areaFrom) {
    filterBy.push(
      `${isProject ? 'maxSqft' : 'propertyArea'}:>=${paramsObject.areaFrom}`
    );
  }

  if (paramsObject.areaTo) {
    filterBy.push(
      `${isProject ? 'minSqft' : 'propertyArea'}:<=${paramsObject.areaTo}`
    );
  }

  if (paramsObject.yearFrom) {
    filterBy.push(
      `${isProject ? 'yearCompletion' : 'yearBuilt'}:>=${paramsObject.yearFrom}`
    );
  }

  if (paramsObject.yearTo) {
    filterBy.push(
      `${isProject ? 'yearCompletion' : 'yearBuilt'}:<=${paramsObject.yearTo}`
    );
  }

  // if (paramsObject.furnished) {
  //   filterBy.push(`furnished:true`);
  // }

  if (
    paramsObject.view &&
    !isProject &&
    paramsObject.view !== 'any' &&
    paramsObject.view !== 'cualquiera'
  ) {
    filterBy.push(`view:=${paramsObject.view}`);
  }

  if (paramsObject.condoCostFrom && !isProject) {
    filterBy.push(`fee:>=${paramsObject.condoCostFrom}`);
  }

  if (paramsObject.condoCostTo && !isProject) {
    filterBy.push(`fee:<=${paramsObject.condoCostTo}`);
  }

  if (paramsObject.hopa === 'hopa-eligible') {
    filterBy.push(`HOPA:true`);
  } else if (paramsObject.hopa === 'no-hopa') {
    filterBy.push(`HOPA:false`);
  }

  if (paramsObject.dock) {
    filterBy.push(`boatDock:true`);
  }

  if (
    paramsObject.leftBound &&
    paramsObject.rightBound &&
    paramsObject.topBound &&
    paramsObject.bottomBound
  ) {
    // if top and bottom are the same or left and right are the same, include 2.5km on each side

    const top = parseFloat(paramsObject.topBound as string);
    const bottom = parseFloat(paramsObject.bottomBound as string);
    const left = parseFloat(paramsObject.leftBound as string);
    const right = parseFloat(paramsObject.rightBound as string);

    const topBottomDiff = Math.abs(top - bottom);
    const leftRightDiff = Math.abs(left - right);

    if (topBottomDiff < 0.0001 || leftRightDiff < 0.0001) {
      if (topBottomDiff < 0.0001) {
        paramsObject.topBound = (top + 0.0025).toString();
        paramsObject.bottomBound = (bottom - 0.0025).toString();
      }
      if (leftRightDiff < 0.0001) {
        paramsObject.leftBound = (left - 0.0025).toString();
        paramsObject.rightBound = (right + 0.0025).toString();
      }
    }

    filterBy.push(
      'geopoint:(' +
        `${paramsObject.topBound},${paramsObject.leftBound},` +
        `${paramsObject.bottomBound},${paramsObject.leftBound},` +
        `${paramsObject.bottomBound},${paramsObject.rightBound},` +
        `${paramsObject.topBound},${paramsObject.rightBound}` +
        ')'
    );
  }

  if (filterBy.length > 0) {
    queryParams.filter_by = filterBy.join(' && ');
  }

  if (paramsObject.includeFields) {
    queryParams.include_fields = Array.isArray(paramsObject.includeFields)
      ? paramsObject.includeFields.join(',')
      : (paramsObject.includeFields as string);
  }

  return queryParams;
}
