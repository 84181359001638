declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (config: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hsq: any[];
    loadingHubspotMain: Promise<boolean>;
    loadingHubspot: Promise<boolean>;
    hubspotIdentified?: boolean;
  }
}

let sharedComposableData: {
  createForm: (config: {
    region: string;
    portalId: string;
    formId: string;
    target: string;
    onFormSubmitted?: (form: unknown) => void;
    translations?: Record<string, Record<string, string>>;
  }) => void;
  fixForms: () => void;
  routeWatcher: () => void;
  triggerEvent: (
    name: string,
    properties?: Record<string, unknown>
  ) => Promise<void>;
  loadMainScript: () => Promise<unknown>;
} | null = null;

export default function () {
  if (sharedComposableData) {
    return sharedComposableData;
  }

  async function loadMainScript() {
    if (!process.client || typeof window === 'undefined') {
      return;
    }

    if (window._hsq) {
      return { _hsq: window._hsq };
    }

    if (window.loadingHubspotMain) {
      await window.loadingHubspotMain;
    }

    if (window._hsq) {
      return { _hsq: window._hsq };
    }

    // eslint-disable-next-line no-async-promise-executor
    window.loadingHubspotMain = new Promise(async (resolve, reject) => {
      // this code shouldn't run since _hsq is loaded before this
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (window._hsq) {
        resolve(true);
        return;
      }
      const script = document.createElement('script');
      script.defer = true;
      script.src = '//js.hs-scripts.com/23281212.js';
      script.id = 'hs-script-loader';
      document.head.appendChild(script);

      // window.dispatchEvent(new CustomEvent('ptupdate'));

      script.addEventListener('load', () => {
        resolve(true);
      });
      script.addEventListener('error', e => {
        reject(e);
      });
    });

    await window.loadingHubspotMain;

    return { hbspt: window.hbspt, _hsq: window._hsq };
  }

  async function loadScript() {
    if (!process.client || typeof window === 'undefined') {
      return;
    }

    if (window.hbspt) {
      return { hbspt: window.hbspt };
    }

    if (window.loadingHubspot) {
      await window.loadingHubspot;
    }

    if (window.hbspt) {
      return { hbspt: window.hbspt };
    }

    // eslint-disable-next-line no-async-promise-executor
    window.loadingHubspot = new Promise(async (resolve, reject) => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (window.hbspt) {
        resolve(true);
        return;
      }
      const formsScript = document.createElement('script');
      formsScript.src = 'https://js.hsforms.net/forms/v2.js';
      formsScript.defer = true;
      document.body.appendChild(formsScript);
      formsScript.addEventListener('load', () => {
        resolve(true);
      });
      formsScript.addEventListener('error', e => {
        reject(e);
      });
    });

    await window.loadingHubspot;

    return { hbspt: window.hbspt, _hsq: window._hsq };
  }

  function createForm(config: {
    region: string;
    portalId: string;
    formId: string;
    target: string;
    onFormSubmitted?: (form: unknown) => void;
    translations?: Record<string, Record<string, string>>;
  }) {
    loadScript().then(hubspotObjects => {
      if (!hubspotObjects?.hbspt) {
        return;
      }
      hubspotObjects.hbspt.forms.create(config);
    });
  }

  function fixForms() {
    if (typeof window === 'undefined') {
      return;
    }

    const forms = document.querySelectorAll('.hubspotForm');

    forms.forEach(form => {
      const formId = form.getAttribute('data-hs-form-id');
      const portalId = form.getAttribute('data-hs-portal-id');
      const region = form.getAttribute('data-hs-region');

      if (!formId || !portalId || !region) {
        return;
      }

      form.removeAttribute('data-hs-form-id');
      form.removeAttribute('data-hs-portal-id');
      form.removeAttribute('data-hs-region');

      createForm({
        region,
        portalId,
        formId,
        target: `#${form.id}`
      });
    });
  }

  const route = useRoute();

  const routeWatcher = watch(
    () => route.path,
    async () => {
      if (typeof window === 'undefined') {
        return;
      }
      await loadMainScript();
      if (!window?._hsq) {
        return;
      }
      window._hsq.push(['setPath', route.path]);
      window._hsq.push(['trackPageView']);
    },
    { immediate: true }
  );

  async function triggerEvent(
    name: string,
    properties: Record<string, unknown> = {}
  ) {
    if (!name || typeof window === 'undefined') {
      return;
    }
    await loadMainScript();
    if (!window?._hsq) {
      return;
    }
    // check if there's a user in _hsq
    // uses a local variable to make it compatible with partytown
    if (window.userEmail && !window.hubspotIdentified) {
      window._hsq.push(['identify', { email: window.userEmail }]);
      window.hubspotIdentified = true;
    }
    window._hsq.push([
      'trackCustomBehavioralEvent',
      {
        name,
        properties
      }
    ]);
  }

  sharedComposableData = {
    createForm,
    fixForms,
    routeWatcher,
    triggerEvent,
    loadMainScript
  };

  return sharedComposableData;
}
