export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'global-redirect',
    (to, from) => {
      if (to.path === from.path && !to.hash) {
        console.warn('Paths are the same, redirecting', to.path);
        return false;
      }
    },
    { global: true }
  );
});
