export function fixImageUrl(value: string) {
  return value.replace('://pfsrealty.us/wp-', '://pfsrealty.kinsta.cloud/wp-');
  // .replaceAll('://pfsrealty.com/blog', '://pfsblog.kinsta.cloud/blog')
  // .replaceAll('://pfsrealty.com/pfs', '://pfsrealty.kinsta.cloud/pfs')
  // .replaceAll('://pfsrealty.us/pfs', '://pfsrealty.kinsta.cloud/');
}

export function fixFileUrl(value: string) {
  return value
    .replace('://pfsrealty.us/wp-', '://pfsrealty.kinsta.cloud/wp-')
    .replace('://pfsrealty.com/wp-', '://pfsrealty.kinsta.cloud/wp-');
}

export function fixDataUrl(value: string) {
  return value
    .replaceAll('://pfsrealty.kinsta.cloud/', `://pfsrealty.com/`)
    .replaceAll(
      '://pfsrealty.com/wp-content',
      '://pfsrealty.kinsta.cloud/wp-content'
    )
    .replaceAll(
      '://pfsrealty.us/wp-content',
      '://pfsrealty.kinsta.cloud/wp-content'
    );
}

export function fixContent(value: string, imageProxyUrl: string) {
  let newContent = fixDataUrl(value);

  const matches = newContent.match(/<img[^>]+>/g);

  if (matches) {
    matches.forEach(match => {
      const previousSrc: RegExpMatchArray | null = match.match(/src="([^"]+)"/);
      const previousSrcset: RegExpMatchArray | null =
        match.match(/srcset="([^"]+)"/);
      const previousSizes: RegExpMatchArray | null =
        match.match(/sizes="([^"]+)"/);
      const previousClass: RegExpMatchArray | null =
        match.match(/class="([^"]+)"/);

      const width = match.match(/width="([^"]+)"/);
      const height = match.match(/height="([^"]+)"/);

      if (previousSrc && width && height) {
        const previousImageUrl = previousSrc[1];
        const newSrc = `${imageProxyUrl}/image?w=${width[1]}&h=${height[1]}&f=webp&fit=crop&image=${previousImageUrl}`;
        const newSrcset = `${newSrc} 1x, ${imageProxyUrl}/image?w=${
          +width[1] * 2
        }&h=${+height[1] * 2}&f=webp&fit=crop&image=${previousImageUrl} 2x`;

        let newMatch = match;

        if (previousSrcset) {
          newMatch = newMatch
            .replace(`srcset="${previousSrcset[1]}"`, `srcset="${newSrcset}"`)
            .replace(`src="${previousSrc[1]}"`, `src="${newSrc}"`);
        } else {
          // If there is no srcset, we add it
          newMatch = newMatch.replace(
            `src="${previousSrc[1]}"`,
            `src="${newSrc}" srcset="${newSrcset}`
          );
        }

        if (previousSizes) {
          newMatch = newMatch.replace(
            `sizes="${previousSizes[1]}"`,
            `sizes="1x 2x"`
          );
        }

        if (previousClass) {
          if (+width[1] > 400) {
            newMatch = newMatch.replace(
              `class="${previousClass[1]}`,
              `class="${previousClass[1]} figure-img`
            );
          }
        } else {
          newMatch = newMatch.replace(
            `src="${newSrc}"`,
            `src="${newSrc}" class="figure-img"`
          );
        }

        newContent = newContent.replace(match, newMatch);
      }
    });
  }

  return newContent;
}

export function fixLinkUrl(value: string) {
  return value.replaceAll('://pfsrealty.kinsta.cloud/', `://pfsrealty.com/`);
}

interface GraphEntry {
  '@type': string;
  potentialAction?: unknown;
}

type SchemaGraph =
  | {
      '@graph': GraphEntry[];
    }
  | GraphEntry[];

export function getGraph(schemaJson: SchemaGraph) {
  const graph = ((schemaJson &&
  typeof schemaJson === 'object' &&
  !Array.isArray(schemaJson) &&
  '@graph' in schemaJson
    ? schemaJson['@graph']
    : schemaJson) || []) as GraphEntry[];

  const websiteIndex = graph?.findIndex
    ? graph?.findIndex(e => e['@type'] === 'WebSite')
    : null;

  if (graph?.[websiteIndex]?.potentialAction) {
    delete graph[websiteIndex].potentialAction;
  }

  return graph;
}
