import {
  ProjectDocumentResponse,
  PropertyDocumentResponse
} from '../types/properties';

import { propertySubTypesOptions } from './search';

function slugify(str: string) {
  return (
    str
      .toString()
      .toLowerCase()
      // .replaceAll(' - ', '---')
      // .replaceAll('-', '--')
      .replaceAll(' ', '-')
      .replaceAll('_', '-')
      .replaceAll('/', '-slash-')
      .normalize('NFD')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
  );
}

const labelsItemParams = [
  'subType',
  'projectType',
  'type',
  'projectStatus',
  'sortBy'
];

const paramsWithKeys = {
  city: 'paramsKeys.city',
  county: 'paramsKeys.county',
  listPrice: 'paramsKeys.price',
  minPrice: 'paramsKeys.price',
  bathsFull: 'paramsKeys.bathsFull',
  minBaths: 'paramsKeys.bathsFull',
  bedrooms: 'paramsKeys.bedrooms',
  minBeds: 'paramsKeys.bedrooms',
  propertyArea: 'paramsKeys.area',
  minSqft: 'paramsKeys.area',
  subType: 'paramsKeys.propertySubType'
} as const;

function getParam(
  p: Partial<PropertyDocumentResponse> | Partial<ProjectDocumentResponse>,
  key: string | string[],
  t: (v: string, d: string) => string
) {
  let value = '';
  let currentValue = '';

  if (Array.isArray(key)) {
    currentValue = key.map(k => p[k]).join('-');
    key = key[0];
    return value;
  } else {
    currentValue = p[key];
  }

  if (labelsItemParams.includes(key)) {
    value = t(`paramsAliases.${currentValue}`, currentValue);
  }

  if (key in paramsWithKeys) {
    const foundKey = paramsWithKeys?.[key as keyof typeof paramsWithKeys];
    if (foundKey && (value || currentValue)) {
      value = (value || currentValue) + '-' + t(foundKey, foundKey);
    }
  }
  return slugify(value || currentValue || '');
}

export function getPropertyLink(
  p: Pick<PropertyDocumentResponse, 'type' | 'county' | 'city' | 'full' | 'id'>,
  t: (v: string, d: string) => string
) {
  return [
    p.type ? getParam(p, 'type', t) : '',
    p.county ? getParam(p, 'county', t) : '',
    p.city ? getParam(p, 'city', t) : '',
    // getParam(p, 'postalCode', t),
    // getParam(p, 'subType', t),
    // getParam(p, 'yearBuilt', t),
    // getParam(p, 'listPrice', t),
    // getParam(p, 'bedrooms', t),
    // getParam(p, 'bathsFull', t),
    // getParam(p, 'propertyArea', t),
    `${getParam(p, 'full', t)}-${getParam(p, 'id', t)}`
  ]
    .filter(Boolean)
    .join('/');
}

export function getProjectLink(
  p: Pick<
    ProjectDocumentResponse,
    'county' | 'city' | 'address' | 'projectName' | 'id'
  >,
  t: (v: string, d: string) => string
) {
  return [
    // getParam(p, 'projectStatus', t),
    p.county ? getParam(p, 'county', t) : '',
    p.city ? getParam(p, 'city', t) : '',
    // getParam(p, 'postalCode', t),
    // getParam(p, 'projectType', t),
    // getParam(p, 'yearCompletion', t),
    // getParam(p, ['minPrice', 'maxPrice'], t),
    // getParam(p, ['minBeds', 'maxBeds'], t),
    // getParam(p, ['minBaths', 'maxBaths'], t),
    // getParam(p, ['minSqft', 'maxSqft'], t),
    getParam(p, 'projectName', t),
    [p.address ? getParam(p, 'address', t) : '', getParam(p, 'id', t)]
      .filter(Boolean)
      .join('-')
  ]
    .filter(Boolean)
    .join('/');
}

export function getUsedProjectLink(
  p: Pick<
    ProjectDocumentResponse,
    'subType' | 'county' | 'city' | 'projectName' | 'address' | 'id'
  >,
  t: (v: string, d: string) => string
) {
  const propertyTypeParamKey = propertySubTypesOptions.find(option => {
    return option.value === p.subType;
  });

  const propertyTypeParam = propertyTypeParamKey?.typeValue
    ? t('paramsAliases.' + propertyTypeParamKey.typeValue, '')
    : '';

  return [
    getParam({ type: 'RES' } as PropertyDocumentResponse, 'type', t),
    propertyTypeParam,
    getParam({ subType: 'building' } as PropertyDocumentResponse, 'subType', t),
    p.county ? getParam(p, 'county', t) : '',
    p.city ? getParam(p, 'city', t) : '',
    getParam(p, 'projectName', t),
    [p.address ? getParam(p, 'address', t) : '', getParam(p, 'id', t)]
      .filter(Boolean)
      .join('-')
  ]
    .filter(Boolean)
    .join('/');
}
