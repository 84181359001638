export default {
  home: {
    seo: {
      title: "Bienes raíces {'|'} Inversiones inmobiliarias en Miami y Orlando",
      description:
        'Encuentra las mejores propiedades de bienes raíces para comenzar a invertir en Miami y Orlando; en PFS Realty te brindamos la asesoría necesaria.'
    },
    hero: {
      title: 'Bienes raíces en venta y alquiler en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Alquilar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Departamentos y Casas en venta y alquiler en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de departamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2.000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 años de experiencia en ventas de viviendas en EE.UU.',
          description:
            'Nuestra larga trayectoria como líderes en el mercado de bienes raíces en Miami nos posiciona como tu mejor opción. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 agentes en 12 países de América y Europa',
          description:
            'Con una red internacional de agentes, ofrecemos perspectivas globales y locales que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus recursos de inversión en bienes raíces.'
        },
        three: {
          title: 'Más de 2.000 clientes satisfechos confían en nosotros',
          description:
            'La confianza depositada en nosotros por nuestros clientes es el reflejo de nuestra dedicación y conocimiento del mercado de bienes raíces. Somos una de las inmobiliarias en Miami más confiables y respetadas. ¡Contáctanos y experimenta el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'Servicios completos',
      properties: {
        title: 'Adquiere la propiedad ideal',
        description:
          'Encuentra la propiedad perfecta en Miami y compra inmuebles nuevos y usados de alta calidad. Nuestros agentes inmobiliarios cuentan con amplia experiencia y conocimiento para brindarte el asesoramiento especializado que necesitas.'
      },
      financing: {
        title: 'Financiamiento a tu medida',
        description:
          'La compra de un inmueble suele requerir de un financiamiento sólido y adaptado a la capacidad económica del comprador. Por eso, ofrecemos créditos hipotecarios en Estados Unidos flexibles y personalizados para peruanos con mínimo 30% de entrada. Adaptamos las condiciones a tus necesidades.'
      },
      extra_income: {
        title: 'Genera ingresos adicionales alquilando',
        description:
          'Te brindamos una asistencia integral para transformar tu inmueble en una fuente de ganancias a través de la renta en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de inmuebles en Miami',
        description:
          'Contamos con un equipo preparado para el cuidado profesional y el mantenimiento preventivo y correctivo de tu vivienda. Optimizamos costos y beneficios para asegurar una administración eficiente en tu inversión en bienes raíces.'
      },
      legal: {
        title: 'Asesoría legal y contable completa',
        description:
          'Nuestro equipo interdisciplinario de expertos proporciona asesoría fiscalista en Miami para aclarar cualquier duda sobre los aspectos legales y contables que involucran la compra, venta y alquiler de bienes inmuebles. Aseguramos el cumplimiento normativo.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  search: {
    buyTypeOptions: {
      rent: 'Alquilar',
      rented: 'Alquilado | Alquilados'
    },
    availableItems: '{items} en venta y alquiler',
    rentedItems: '{items} alquilados',
    rentedItems_female: '{items} alquiladas',
    soldOrRentedItems: '{items} vendidos o alquilados',
    soldOrRentedItems_female: '{items} vendidas o alquiladas',
    itemsForRent: '{items} en alquiler',
    rentSearchResults: '{items} en alquiler en {search}',
    rentedSearchResults: '{items} alquiladas en {search}',
    buyRentSearchResults: '{items} en venta y alquiler en {search}',
    rentSearchResultsNearYou: '{0} en alquiler cerca de tu ubicación',
    rentedSearchResultsNearYou: '{0} alquiladas cerca de tu ubicación',
    buyRentSearchResultsNearYou:
      '{0} en venta y alquiler cerca de tu ubicación',
    rentResults: 'Propiedades en alquiler',
    rentedResults: 'Propiedades alquiladas',
    title: 'Búsqueda de casas, departamentos y otros inmuebles',
    properties: 'Casas y departamentos',
    condoType: 'Departamento',
    condosType: 'Departamentos',
    apartment: 'Departamento',
    apartments: 'Departamentos'
  },
  property: {
    rented: 'Alquilado',
    rented_female: 'Alquilada'
  },
  project: {
    rentals: 'Alquileres',
    availableCondosForRent: 'Departamentos disponibles para alquilar',
    pendingCondosForRent: 'Departamentos pendientes para alquilar',
    closedCondosForRent: 'Departamentos alquilados',
    availableCondosForSale: 'Departamentos disponibles para la venta',
    pendingCondosForSale: 'Departamentos pendientes para la venta',
    closedCondosForSale: 'Departamentos vendidos',
    condos:
      '{project} tiene {n} departamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}'
  },
  footer: {
    rent_label: 'Precio de Alquiler'
  },
  calculator: {
    title: 'Simulador de crédito hipotecario',
    description:
      'Esta herramienta te permite simular el pago mensual de un préstamo hipotecario, considerando factores como el capital e interés, el predial, la comisión por administración y el seguro mensual. <br/>' +
      'Al ingresar los datos sobre el precio del inmueble, el enganche y la tasa de interés, puedes obtener un cálculo preciso de tus pagos mensuales, facilitando la compra y financiamiento de tu vivienda a través de una entidad financiera. <br/>',
    hint: 'Esto es esencial para tu educación financiera y la planificación de la compra de tu vivienda propia, permitiéndote gestionar mejor tu historial crediticio y ahorros.',
    monthlyPayments: 'Pago mensual ',
    principalInterest: 'Capital e intereses',
    propertyTax: 'Impuesto predial',
    associationFee: 'Comisión por administración',
    principalInterestLegend: 'Capital e intereses',
    propertyTaxLegend: 'Impuesto predial',
    associationFeeLegend: 'Pago de mantenimiento',
    insuranceFee: 'Seguro mensual',
    homePrice: 'Precio del inmueble',
    propertyTaxYearly: 'Impuesto predial',
    associationFeeMonthly: 'Comisión por administración',
    insuranceFeeMonthly: 'Seguro mensual',
    downPayment: 'Pago inicial (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de interés (%)',
    loanType: 'Tipo de crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    help:
      'A través de nuestro informe inmobiliario de Miami y Orlando, es posible seleccionar la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada, comparar precios en diversas zonas, evaluar el impacto de las tendencias e inversiones del sector, y anticipar cambios en el mercado para planificar con mayor precisión tus estrategias de adquisición, venta o arrendamiento.<br/>' +
      'Con esta herramienta accedes a datos actualizados y detallados segmentados por distrito, ciudad, urbanización y tipo de inmueble, resultando fundamental para tomar decisiones informadas y planificadas en el sector de inmuebles. Aprovecha esta valiosa información y consulta  el informe completo para optimizar tus inversiones.<br/>',
    title: 'Reporte de mercado inmobiliario',
    longTitle: "Informe inmobiliario {'|'} Reporte de mercado {'|'} PFS Realty",
    description:
      'Conoce nuestro reporte de mercado inmobiliario para tomar las mejores decisiones sobre la inversión inmobiliaria en Miami y Orlando.',
    recentlyRented: 'Recientemente alquilados',
    buyTypes: {
      rentals: 'Alquileres'
    }
  },
  blog: {
    title: 'Oportunidades en Miami y Orlando',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario y mantente informado con nuestras noticias exclusivas. ¡No te pierdas ninguna actualización sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!',
    description:
      'Descubre los temas más relevantes sobre las inversiones inmobiliarias en Miami y Orlando; en PFS te mantenemos informado.',
    seo: {
      title: "Blog informativo {'|'} PFS Realty"
    }
  },
  seo: {
    calculator: {
      longTitle: "Simulador de crédito hipotecario {'|'} PFS Realty Group",
      description:
        'Conoce nuestro simulador de crédito hipotecario y analiza las opciones de inversión inmobiliarias en Miami y Orlando.'
    },
    defaultSingle: 'Casa o departamento',
    defaultMultiple: 'Casas y departamentos',
    multipleSale: 'Casas y departamentos en venta',
    multipleRent: 'Casas y departamentos en alquiler',
    defaultDescription:
      'En PFS Realty encuentra bienes raices e inmuebles en venta y alquiler en la Florida.',
    singleRent: '{0} en alquiler',
    singleRentAlt: '{0} en alquiler',
    complementaryTitle:
      'en PFSRealty encuentra bienes raices e inmuebles en venta y alquiler.',
    counties: {
      description:
        'Encuentra propiedades en venta y alquiler en los condados de la Florida.'
    },
    cities: {
      description:
        'Encuentra propiedades en venta y alquiler en las ciudades de {0}, Florida.'
    },
    neighborhoods: {
      description:
        'Encuentra propiedades en venta y alquiler en los vecindarios de {0}, Florida.'
    }
  }
};
