import { Pinia } from 'pinia';

import { useMainStore } from '@/store';

export default defineNuxtPlugin(async nuxtApp => {
  return;

  const store = useMainStore(nuxtApp.$pinia as Pinia);

  const req = nuxtApp.ssrContext?.event.node.req;

  if (!req) {
    return;
  }

  await store.serverSideData(req);
});
