import cl from './cl';
import co from './co';
import ec from './ec';
import es from './es';
import mx from './mx';
import pe from './pe';
import us from './us';

export default {
  cl,
  co,
  ec,
  es,
  mx,
  pe,
  us
};
